import { MapContainer, TileLayer, Polyline } from "react-leaflet";
import * as React from "react";

import { greenIcon, greyIcon, yellowIcon } from "./markers";
import { RouteMarker } from "./Marker";

const getCenterPosition = (rwgpsData, spotData) => {
  const { first_lat, first_lng } = rwgpsData;

  if (spotData.length === 0) {
    // Should make sure that the route isn't private. Check for null or the error response
    return [first_lat, first_lng];
  }
  const [last] = spotData;
  return [last.latitude, last.longitude];
};

const RideWithGPSPath = ({ rwgpsData }) => {
  const pathOptions = { color: "purple" };
  const points = rwgpsData.track_points.map((tp) => [tp.y, tp.x]);
  return <Polyline pathOptions={pathOptions} positions={points} />;
};

const RideWithGPSControls = ({ rwgpsData }) => {
  return rwgpsData.course_points
    ?.filter((p) => p.t === "Control")
    .map((p) => (
      <RouteMarker
        latitude={p.y}
        longitude={p.x}
        icon={yellowIcon}
        messages={[p.n]}
      />
    ));
};

const RideWithGPSPOI = ({ rwgpsData }) => {
  return rwgpsData.points_of_interest
    ?.map((p) => (
      <RouteMarker
        latitude={p.lat}
        longitude={p.lng}
        icon={yellowIcon}
        messages={[p.name, p.description]}
      />
    ));
};

const getBounds = (rwgpsData) => {
  if (rwgpsData.bounding_box != null) {
    return rwgpsData.bounding_box.map((bb) => [bb.lat, bb.lng]);
  }
  return [];
};

export const Map = (props) => {
  const { rwgpsData, spotData, config } = props;

  return (
    <MapContainer
      center={getCenterPosition(rwgpsData, spotData)}
      zoom={13}
      bounds={getBounds(rwgpsData)}
      scrollWheelZoom={true}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <RideWithGPSPath rwgpsData={rwgpsData} />
      {/* <RideWithGPSPoints rwgpsData={rwgpsData} /> */}
      {config.showControls && <RideWithGPSControls rwgpsData={rwgpsData} />}
      {config.showPOI && <RideWithGPSPOI rwgpsData={rwgpsData} />}
      {spotData.map((message, i) => (
        <RouteMarker icon={i === 0 ? greenIcon : greyIcon} {...message} />
      ))}
    </MapContainer>
  );
};
