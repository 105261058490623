import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Area,
  Dot,
} from "recharts";
import {
  alignPointsOnRoute,
  metersToMiles,
  metersToFeet,
} from "./helpers/distances";

import './CustomToolTip.css'

const ControlPin = ({ cx, cy }) => (
  <svg
    height="75px"
    width="75px"
    version="1.1"
    id="Capa_1"
    viewBox="0 0 1024 1024"
    x={cx}
    y={cy - 15}
  >
    <g>
      <g>
        <path
          // style="fill:#010002;"
          d="M146.667,0C94.903,0,52.946,41.957,52.946,93.721c0,22.322,7.849,42.789,20.891,58.878
			c4.204,5.178,11.237,13.331,14.903,18.906c21.109,32.069,48.19,78.643,56.082,116.864c1.354,6.527,2.986,6.641,4.743,0.212
			c5.629-20.609,20.228-65.639,50.377-112.757c3.595-5.619,10.884-13.483,15.409-18.379c6.554-7.098,12.009-15.224,16.154-24.084
			c5.651-12.086,8.882-25.466,8.882-39.629C240.387,41.962,198.43,0,146.667,0z M146.667,144.358
			c-28.892,0-52.313-23.421-52.313-52.313c0-28.887,23.421-52.307,52.313-52.307s52.313,23.421,52.313,52.307
			C198.98,120.938,175.559,144.358,146.667,144.358z"
        />
        <circle cx="146.667" cy="90.196" r="21.756" />
      </g>
    </g>
  </svg>
);

const yAxisTicks = (trackPoints) => {
  const yValues = trackPoints.map(({ elevationFt }) => elevationFt);

  const minimumElevation = Math.min(...yValues);
  const maximumElevation = Math.max(...yValues);

  const roundedMinElevation = Math.floor(minimumElevation / 100) * 100;
  const roundedMaxElevation = Math.ceil(maximumElevation / 100) * 100;

  const ticks = [];
  for (let i = roundedMinElevation; i <= roundedMaxElevation; i += 100) {
    ticks.push(Math.round(i, 2));
  }
  return {
    min: roundedMinElevation,
    max: roundedMaxElevation,
    ticks,
  };
};

const getDot = (referenceDotPositions) => {
  return (props) => {
    const { cx, cy, payload } = props;
    for (const dotPosition of referenceDotPositions) {
      if (payload.d === dotPosition.tp.d) {
        // Somewhat of a hack to get controls to show up
        if (dotPosition.tp.t != null) {
          return <ControlPin cx={cx} cy={cy} />;
        }
        return <Dot {...props} fill="red" />;
      }
    }
    return null;
  };
};

const CustomToolTip = ({active, payload, label}) => {
  if (active && payload && payload.length > 0) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${label} miles`}</p>
        <p className="desc">{`${Math.round(payload[0].value)}`} elevation</p>
      </div>
    );
  }
}

export const ElevationProfile = ({ rwgpsData, spotData, config }) => {
  const trackPoints = rwgpsData.track_points.filter(tp => tp.e != null).map((tp) => ({
    ...tp,
    distanceMi: metersToMiles(tp.d),
    elevationFt: metersToFeet(tp.e),
  }));

  const yTicks = yAxisTicks(trackPoints);
  let referenceDotPositions = alignPointsOnRoute(rwgpsData, spotData);

  if (config.showControls === true) {
    const controlPoints = rwgpsData.course_points
      ?.filter((p) => p.t === "Control")
      .map((tp) => ({ tp }));

    referenceDotPositions = [
      ...alignPointsOnRoute(rwgpsData, spotData),
      ...controlPoints,
    ];
  }

  return (
    <ResponsiveContainer
      width="100%"
      height={200}
      className={"elevation-profile"}
    >
      <ComposedChart
        data={trackPoints}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis
          dataKey="distanceMi"
          type="number"
          domain={["dataMin", "dataMax"]}
          tickFormatter={(xValue) => xValue.toFixed(0)}
        />
        <YAxis
          dataKey="elevationFt"
          type="number"
          domain={[yTicks.min, yTicks.max]}
          ticks={yTicks.ticks}
          tickFormatter={(yValue) => `${yValue / 1000}k`}
        />
        <Tooltip content={<CustomToolTip />} />
        <Area
          type="monotone"
          dataKey={({ elevationFt }) => elevationFt}
          fill="#8884d8"
          stroke="#8884d8"
          dot={getDot(referenceDotPositions)}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
