import React, { useState, useEffect } from "react";
import "./App.css";
import axios from "axios";

import { Map } from "./Map";
import { RideInfo } from "./RideInfo";
import { useParams } from "react-router";
import { ElevationProfile } from "./ElevationProfile";
import {
  EventConfig,
  RideWithGPSResponse,
  RouteInfoResponse,
  SpotMessage,
} from "./types";
import NotFound from "./NotFound";

function App() {
  const [spotData, setSpotData] = useState<SpotMessage[]>([]);
  const [rwgpsData, setRwgpsData] = useState<RideWithGPSResponse | object>({});
  const [config, setConfig] = useState<EventConfig>({
    showControls: false,
    showPOI: false,
  });
  const [loading, setLoading] = useState<boolean>(true);

  const [shouldRefresh, setShouldRefresh] = useState<boolean>(false);

  const { eventId = "" } = useParams();
  const getRouteData = async (eventId: string) => {
    try {
      const resp = await axios.post<RouteInfoResponse>("api/routes", {
        event_id: eventId,
      });
      console.log(JSON.stringify(resp.data.config))
      setSpotData(resp.data.spot);
      setRwgpsData(resp.data.rwgps);
      setConfig(resp.data.config);
    } catch (err) {
      setSpotData([]);
      setRwgpsData({});
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRouteData(eventId);
  }, [shouldRefresh, eventId]);

  const refreshData = () => setShouldRefresh(!shouldRefresh);

  let contentToRender = <NotFound />;

  if (loading === true) {
    contentToRender = <p>Loading...</p>;
  } else if (Object.keys(rwgpsData).length > 0) {
    contentToRender = (
      <>
        <RideInfo
          spotData={spotData}
          rwgpsData={rwgpsData}
          refreshData={refreshData}
        />
        <Map spotData={spotData} rwgpsData={rwgpsData} config={config} />
        <ElevationProfile
          rwgpsData={rwgpsData}
          spotData={spotData}
          config={config}
        />
      </>
    );
  }

  return <div className="App">{contentToRender}</div>;
}

export default App;
