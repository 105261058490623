import * as L from 'leaflet';

import black from './images/marker-icon-2x-black.png';
import blue from './images/marker-icon-2x-blue.png';
import gold from './images/marker-icon-2x-gold.png';
import green from './images/marker-icon-2x-green.png';
import grey from './images/marker-icon-2x-grey.png';
import orange from './images/marker-icon-2x-orange.png';
import red from './images/marker-icon-2x-red.png';
import violet from './images/marker-icon-2x-violet.png';
import yellow from './images/marker-icon-2x-yellow.png';
import shadow from './images/marker-shadow.png';


export const blueIcon = new L.Icon({
	iconUrl: blue,
	shadowUrl: shadow,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

export const goldIcon = new L.Icon({
	iconUrl: gold,
	shadowUrl: shadow,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

export const redIcon = new L.Icon({
	iconUrl: red,
	shadowUrl: shadow,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

export const greenIcon = new L.Icon({
	iconUrl: green,
	shadowUrl: shadow,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

export const orangeIcon = new L.Icon({
	iconUrl: orange,
	shadowUrl: shadow,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

export const yellowIcon = new L.Icon({
	iconUrl: yellow,
	shadowUrl: shadow,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

export const violetIcon = new L.Icon({
	iconUrl: violet,
	shadowUrl: shadow,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

export const greyIcon = new L.Icon({
	iconUrl: grey,
	shadowUrl: shadow,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

export const blackIcon = new L.Icon({
	iconUrl: black,
	shadowUrl: shadow,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});
