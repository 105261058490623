import React, {useState, useEffect} from "react";
import dayjs from "dayjs";
import { getDistanceFromLatLonInKm, metersToMiles, alignPointsOnRoute } from "./helpers/distances";
import RelativeTime from "dayjs/plugin/relativeTime";
import UTC from "dayjs/plugin/utc";
import TZ from "dayjs/plugin/timezone";
dayjs.extend(RelativeTime);
dayjs.extend(UTC);
dayjs.extend(TZ);

const REFRESH_SECONDS = 60 * 1000;

const getDistanceRemaining = (spotData, rwgpsData) => {
  const alignedTrackPoints = alignPointsOnRoute(rwgpsData, spotData);

  if (alignedTrackPoints.length === 0) {
    return 0
  }


  // get the last spot message
  const [last] = alignedTrackPoints;
  const { latitude, longitude } = last;
  // get the distance from all the points in rwgps
  const distancesFromLastPoint = alignedTrackPoints.map(({tp}) => ({
    tp,
    distance: getDistanceFromLatLonInKm(latitude, longitude, tp.y, tp.x),
  }));

  // get the minimum
  const sortedDistances = distancesFromLastPoint.sort((a, b) => {
    if (a.distance > b.distance) return 1;
    else if (a.distance < b.distance) return -1;
    else return 0;
  });
  const closestPoint = sortedDistances[0];
  return rwgpsData.distance - closestPoint.tp.d;
};

export const RideInfo = (props) => {
  const { rwgpsData, spotData, refreshData } = props;
  const [timeRemaining, setTimeRemaining] = useState(REFRESH_SECONDS)

  useEffect(() => {
    const timeout = setTimeout(() => {
        setTimeRemaining(timeRemaining - 1000);
    }, 1000);
    return () => {
        if (timeRemaining <= 0){
            clearTimeout(timeout);
            setTimeRemaining(REFRESH_SECONDS);
            refreshData();
        }
    }
  }, [timeRemaining]);

  const totalDistance = metersToMiles(rwgpsData.distance);

  let info = [
    {
      key: "Auto-refresh in",
      value: timeRemaining / 1000,
    },
    {
      key: "Total Distance",
      value: `${totalDistance} mi`,
    },
  ];

  if (spotData.length === 0) {
    info.push({
        key: "No Spot points found yet",
        value: ""
    })
  } else {
      const distanceRemaining = metersToMiles(
        getDistanceRemaining(spotData, rwgpsData)
      );
    
      const [lastSpotPoint] = spotData;
      const lastTimeSeen = new Date(lastSpotPoint.dateTime);
      const lastPosition = `${lastSpotPoint.longitude}, ${lastSpotPoint.latitude}`;

    info = [
        ...info,
        {
            key: "Distance Remaining",
            value: `${distanceRemaining} mi`,
          },
          {
            key: "Last Time Seen",
            value: `${lastTimeSeen.toLocaleString()} (${dayjs(
              lastTimeSeen
            ).fromNow()})`,
          },
          {
            key: "Last Position",
            value: lastPosition,
          },
    ]

  }

  return (
    <div className="ride-info">
      <ul>
        {info.map(({ key, value }) => (
          <li>
            <b>{key}</b>: {value}
          </li>
        ))}
      </ul>
    </div>
  );
};
