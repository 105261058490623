import * as React from "react";
import {
  Marker,
  Popup,
} from "react-leaflet";


export const RouteMarker = ({
  latitude,
  longitude,
  icon,
  dateTime,
  messages = [],
}) => {
  return (
    <Marker position={[latitude, longitude]} icon={icon}>
      <Popup>
        <ul>
          {dateTime != null && <li>{new Date(dateTime).toLocaleString()}</li>}
          {messages.length > 0 && messages.map(m => (<li>{m}</li>))}
          <li>
            {latitude}, {longitude}
          </li>
        </ul>
      </Popup>
    </Marker>
  );
};
