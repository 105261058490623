// https://en.wikipedia.org/wiki/Haversine_formula

import { RideWithGPSResponse, SpotMessage } from "../types";

// https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
export function getDistanceFromLatLonInKm(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
}

const deg2rad = (deg: number) => {
  return deg * (Math.PI / 180);
};

export const alignPointsOnRoute = (
  rwgpsData: RideWithGPSResponse,
  spotData: SpotMessage[]
) => {
  // Found the variance to be between 0.000948 and 0.038388
  const distanceEpsilon = 0.038;

  const routeDotPositions = [];
  for (const spotPoint of spotData) {
    for (const tp of rwgpsData.track_points) {
      if (
        getDistanceFromLatLonInKm(
          spotPoint.latitude,
          spotPoint.longitude,
          tp.y,
          tp.x
        ) <= distanceEpsilon
      ) {
        routeDotPositions.push({ tp });
        break;
      }
    }
  }
  return routeDotPositions;
};

export const metersToMiles = (m: number) =>
  Number((m / 1000 / 1.609344).toFixed(2));
export const metersToFeet = (m: number) => Number(m / 0.3048);
