import React  from "react";


function App() {
  return (
    <div className="App">
      <p>Page not found. If you're looking to track an event, you'll need an event ID</p>
    </div>
  );
}

export default App;
